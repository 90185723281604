import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Settings.css';
import {DateTime} from 'luxon';
import TimePicker from 'react-time-picker';
import { FaClock, FaCheck} from 'react-icons/fa';
import './TimePicker.css';
import 'react-time-picker/dist/TimePicker.css';
import './Dropdown.css';

const StoreSettings = () => {
  const [storeParams, setStoreParams] = useState(null);
  const [error, setError] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenHours, setDropdownOpenHours] = useState(false);
  const [dropdownCloseHours, setDropdownCloseHours] = useState(false);
  const [dropdownLastDelivery, setDropdownLastDelivery] = useState(false);
  const [dropdownDeliveryPrice, setDropdownDeliveryPrice] = useState(false);
  const [dropdownDeliveryStartPrice, setDropdownDeliveryStartPrice] = useState(false);
  const [dropdownSiret, setDropdownSiret] = useState(false);
  const [dropdownPhone, setDropdownPhone] = useState(false);
  const [value, onChange] = useState('10:00');
  const [value2, onChange2] = useState('10:00');
  const [value3, onChange3] = useState('10:00');
  const [delivery, onChangeDelivery] = useState(1);
  const [deliveryPrice, onChangeDeliveryPrice] = useState(1);
  const [deliveryStartPrice, onChangeDeliveryStartPrice] = useState(1);
  const [siret, onChangeSiret] = useState('');
  const [phone, onChangePhone] = useState('');

  const url = `${window.env.REACT_APP_BASE_URL}parameter-store/`;
  const siret_url = `${window.env.REACT_APP_BASE_URL}siret/`;
  const jwt = localStorage.getItem('jwt');
  const storeId = localStorage.getItem('storeId');

  // Fetch store parameters from the API when the component mounts
  useEffect(() => {

    if (!storeId || !jwt) {
      setError('Store ID or JWT token is missing.');
      return;
    }


    const fetchStoreParams = async () => {
      try {
        const url = `${window.env.REACT_APP_BASE_URL}parameter-store/?store_id=${storeId}`;
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${jwt}` // Send the JWT token in the Authorization header
          },
          withCredentials: true // Ensure cookies are sent with the request
        });
        setStoreParams(res.data);
      } catch (err) {
        console.error('Error fetching store parameters:', err);
        setError(`Failed to fetch store parameters. Please try again later. Error: ${err.message}, Details: ${err.response?.data}`);
      }
    };

    const fetchSiret = async () => {
      try {
        const url = `${window.env.REACT_APP_BASE_URL}siret/?store_id=${storeId}`;
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${jwt}`
          },
          withCredentials: true
        });
        onChangeSiret(res.data);
      } catch (err) {
        console.error('Error fetching siret number:', err);
        setError(`Failed to fetch siret number. Please try again later. Error: ${err.message}, Details: ${err.response?.data}`);
      }
    };

    fetchStoreParams();
    fetchSiret();
  }, []);

  // Edit the delivery open status
  const toggleDeliveryOpen = async () => {
    try {
      const delivery_open = !storeParams.delivery_open;
      const store_id = storeId;

      await axios.put(
          url, 
          { store_id, delivery_open },
          {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
          }
      );

      setStoreParams(prevState => ({
          ...prevState,
          delivery_open: delivery_open
      }));
    } catch (err) {
        console.error(err.response?.data || err.message);
        setError('ERROR.');
    }
  };

  // Edit the delivery radius
  const toggleDropdown = (value) => {
    onChangeDelivery(value);
    setDropdownOpen(!dropdownOpen);
    setDropdownOpenHours(false);
    setDropdownLastDelivery(false);
    setDropdownDeliveryPrice(false);
    setDropdownDeliveryStartPrice(false);
    setDropdownSiret(false);
    setDropdownPhone(false);
  };

  const togglePhone = (value) => {
    onChangePhone(value);
    setDropdownOpen(false);
    setDropdownOpenHours(false);
    setDropdownLastDelivery(false);
    setDropdownDeliveryPrice(false);
    setDropdownDeliveryStartPrice(false);
    setDropdownSiret(false);
    setDropdownPhone(!dropdownPhone);
  };

  const handleRadiusChange = async (radius) => {
    setDropdownOpen(false);
    
    const delivery_radius_km = radius;
    const store_id = storeId;

    await axios.put(
        url, 
        { store_id, delivery_radius_km },
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    setStoreParams(prevParams => ({
      ...prevParams,
      delivery_radius_km: radius,
    }));
  };

  const handlePhoneChange = async (item) => {
    setDropdownPhone(false);
    
    const phone_number = item;
    const store_id = storeId;

    await axios.put(
        url, 
        { store_id, phone_number },
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    setStoreParams(prevParams => ({
      ...prevParams,
      phone_number: item,
    }));
  };

  const handleOpenHoursChange = async (option) => {
    setDropdownOpenHours(false);
    
    const open_hours = option + ':00';
    const store_id = storeId;

    await axios.put(
        url, 
        { store_id, open_hours},
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    setStoreParams(prevParams => ({
      ...prevParams,
      open_hours,
    }));
  };

  const toggleDropdownHours = (value) => {
    onChange3(DateTime.fromFormat(value, "HH:mm:ss").toFormat("HH:mm"));
    setDropdownOpenHours(!dropdownOpenHours);
    setDropdownOpen(false);
    setDropdownLastDelivery(false);
    setDropdownDeliveryPrice(false);
    setDropdownDeliveryStartPrice(false);
    setDropdownCloseHours(false);
    setDropdownSiret(false);
    setDropdownPhone(false);
  };

  const toggleDropdownCloseHours = (value) => {
    onChange2(DateTime.fromFormat(value, "HH:mm:ss").toFormat("HH:mm"));
    setDropdownCloseHours(!dropdownCloseHours);
    setDropdownOpenHours(false);
    setDropdownOpen(false);
    setDropdownLastDelivery(false);
    setDropdownDeliveryPrice(false);
    setDropdownDeliveryStartPrice(false);
    setDropdownSiret(false);
    setDropdownPhone(false);
  };

  const handleCloseHoursChange = async (option) => {
    setDropdownCloseHours(false);

    const close_hours = option + ':00';
    const store_id = storeId;

    await axios.put(
        url, 
        { store_id, close_hours},
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    setStoreParams(prevParams => ({
      ...prevParams,
      close_hours,
    }));
  };

  // Edit the last order deadline
  const toggleDropdownLastDelivery = (value) => {
    onChange(DateTime.fromFormat(value, "HH:mm:ss").toFormat("HH:mm"));
    setDropdownLastDelivery(!dropdownLastDelivery);
    setDropdownOpen(false);
    setDropdownOpenHours(false);
    setDropdownDeliveryPrice(false);
    setDropdownDeliveryStartPrice(false);
    setDropdownCloseHours(false);
    setDropdownSiret(false);
    setDropdownPhone(false);
  };

  const handleLastDeliveryChange = async (option) => {
    setDropdownLastDelivery(false);

    const last_order_deadline = option + ':00';
    const store_id = storeId;

    await axios.put(
        url, 
        { store_id, last_order_deadline},
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    setStoreParams(prevParams => ({
      ...prevParams,
      last_order_deadline,
    }));
  }

  //Edit the delivery price
  const toggleDropdownDeliveryPrice = (value) => {
    onChangeDeliveryPrice(value);
    setDropdownDeliveryPrice(!dropdownDeliveryPrice);
    setDropdownOpen(false);
    setDropdownOpenHours(false);
    setDropdownLastDelivery(false);
    setDropdownDeliveryStartPrice(false);
    setDropdownCloseHours(false);
    setDropdownSiret(false);
    setDropdownPhone(false);
  };

  const handleDeliveryPriceChange = async (price) => {
    setDropdownDeliveryPrice(false);

    const delivery_price = price;
    const store_id = storeId;

    await axios.put(
        url, 
        { store_id, delivery_price},
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    setStoreParams(prevParams => ({
      ...prevParams,
      delivery_price,
    }));
  }

  //Edit the delivery start price
  const toggleDropdownDeliveryStartPrice = (value) => {
    onChangeDeliveryStartPrice(value);
    setDropdownDeliveryStartPrice(!dropdownDeliveryStartPrice);
    setDropdownOpen(false);
    setDropdownOpenHours(false);
    setDropdownLastDelivery(false);
    setDropdownDeliveryPrice(false);
    setDropdownCloseHours(false);
    setDropdownSiret(false);
    setDropdownPhone(false);
  };

  //Edit the Siret number
  const toggleDropdownSiret = (value) => {
    onChangeSiret(value);
    setDropdownSiret(!dropdownSiret);
    setDropdownDeliveryStartPrice(false);
    setDropdownOpen(false);
    setDropdownOpenHours(false);
    setDropdownLastDelivery(false);
    setDropdownDeliveryPrice(false);
    setDropdownCloseHours(false);
    setDropdownPhone(false);
  };

  const handleDeliveryStartPriceChange = async (price) => {
    setDropdownDeliveryStartPrice(false);

    const delivery_start_price = price;
    const store_id = storeId;

    await axios.put(
        url, 
        { store_id, delivery_start_price},
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    setStoreParams(prevParams => ({
      ...prevParams,
      delivery_start_price,
    }));
  }

  const handleSiretChange = async (siret_number) => {
    setDropdownSiret(false);

    const siret = siret_number;
    const store_id = storeId;

    await axios.put(
        siret_url, 
        { store_id, siret},
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }
    );
    
    // Update the local state as well
    onChangeSiret(siret);
  }

  return (
    <div className="orders-container">
        <div className="settings-container">
        {error && <p className="error-message">{error}</p>}
        {storeParams ? (
            <div className="store-parameters">
            <div className="store-parameter">
                <p>
                Rayon de livraison &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="switch">
                    <input type="checkbox" checked={storeParams.delivery_open} onChange={toggleDeliveryOpen} />
                    <span className="slider round"></span>
                </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {dropdownOpen ? null : (
                    <strong>{storeParams.delivery_radius_km} KM</strong> 
                )}
                <span className={`dropdown-arrow ${dropdownOpen ? 'open' : ''}`} onClick={() => toggleDropdown(storeParams.delivery_radius_km)}>▼</span>
                </p>
                {dropdownOpen && (
                <>
                <div className="time-picker-container">
                    <input
                    type="number"
                    className="input-field"
                    onChange={(e) => onChangeDelivery(e.target.value)}
                    value={delivery}
                    min="0"
                    />
                    <FaCheck onClick={() => handleRadiusChange(delivery)} className="confirm-icon" />
                </div>
                </>
                )}
            </div>
            <div className="store-parameter">
                <p>
                Horaire d’ouverture
                {dropdownOpenHours ? null : (
                    <strong> {DateTime.fromFormat(storeParams.open_hours, "HH:mm:ss").toFormat("HH:mm")} h</strong>
                )}
                <span className={`dropdown-arrow ${dropdownOpenHours ? 'open' : ''}`}  onClick={() => toggleDropdownHours(storeParams.open_hours)}>▼</span>
                {dropdownOpenHours && (
                    <>
                    <div className="time-picker-container">
                    <TimePicker
                        onChange={onChange3}
                        value={value3}
                        clockIcon={<FaClock />}
                        clearIcon={null}
                        disableClock={true}
                        format="HH:mm"
                    />
                    <FaCheck onClick={() => handleOpenHoursChange(value3)} className="confirm-icon" />
                    </div>
                    </>
                )}
                </p>
            </div>
            <div className="store-parameter">
                <p>
                Horaire fermeture
                {dropdownCloseHours ? null : (
                    <strong> {DateTime.fromFormat(storeParams.close_hours, "HH:mm:ss").toFormat("HH:mm")} h</strong>
                )}
                <span className={`dropdown-arrow ${dropdownCloseHours ? 'open' : ''}`}  onClick={() => toggleDropdownCloseHours(storeParams.close_hours)}>▼</span>
                {dropdownCloseHours && (
                    <>
                    <div className="time-picker-container">
                    <TimePicker
                        onChange={onChange2}
                        value={value2}
                        clockIcon={<FaClock />}
                        clearIcon={null}
                        disableClock={true}
                        format="HH:mm"
                    />
                    <FaCheck onClick={() => handleCloseHoursChange(value2)} className="confirm-icon" />
                    </div>
                    </>
                )}
                </p>
            </div>
            <div className="store-parameter">
                <p>
                Dernière commande
                {dropdownLastDelivery ? null : (
                    <strong>{DateTime.fromFormat(storeParams.last_order_deadline, "HH:mm:ss").toFormat("HH:mm")} h</strong>
                )}              
                <span className={`dropdown-arrow ${dropdownLastDelivery ? 'open' : ''}`} onClick={() => toggleDropdownLastDelivery(storeParams.last_order_deadline)}>▼</span>
                {dropdownLastDelivery && (
                    <>
                    <div className="time-picker-container">
                    <TimePicker
                        onChange={onChange}
                        value={value}
                        clockIcon={<FaClock />}
                        clearIcon={null}
                        disableClock={true}
                        format="HH:mm"
                    />
                    <FaCheck onClick={() => handleLastDeliveryChange(value)} className="confirm-icon" />
                    </div>
                    </>
                )}
                </p>
            </div>
            <div className="store-parameter">
                <p>
                Prix Livraison
                {dropdownDeliveryPrice ? null : (
                    <strong>{storeParams.delivery_price} €</strong>
                )}
                </p>
                <span className={`dropdown-arrow ${dropdownDeliveryPrice ? 'open' : ''}`} onClick={() => toggleDropdownDeliveryPrice(storeParams.delivery_price)}>▼</span>
                {dropdownDeliveryPrice && (
                    <>
                    <div className="time-picker-container">
                    <input
                        type="number"
                        className="input-field"
                        onChange={(e) => onChangeDeliveryPrice(e.target.value)}
                        value={deliveryPrice}
                        min="0"
                    />
                    <FaCheck onClick={() => handleDeliveryPriceChange(deliveryPrice)} className="confirm-icon" />
                    </div>
                    </>
                )}
            </div>
            <div className="store-parameter">
                <p>
                Numéro de téléphone
                {dropdownPhone ? null : (
                    <strong>{storeParams.phone_number}</strong>
                )}
                </p>
                <span className={`dropdown-arrow ${dropdownPhone ? 'open' : ''}`} onClick={() => togglePhone(storeParams.phone_number)}>▼</span>
                {dropdownPhone && (
                    <>
                    <div className="time-picker-container">
                    <input
                        type="text"
                        className="input-field"
                        onChange={(e) => onChangePhone(e.target.value)}
                        value={phone}
                    />
                    <FaCheck onClick={() => handlePhoneChange(phone)} className="confirm-icon" />
                    </div>
                    </>
                )}
            </div>
            <div className="store-parameter">
                <p>
                Livraison à partir de 
                {dropdownDeliveryStartPrice ? null : (
                    <strong>{storeParams.delivery_start_price} €</strong>
                )}
                </p>
                <span className={`dropdown-arrow ${dropdownDeliveryStartPrice ? 'open' : ''}`}  onClick={() => toggleDropdownDeliveryStartPrice(storeParams.delivery_start_price)}>▼</span>
                {dropdownDeliveryStartPrice && (
                    <>
                    <div className="time-picker-container">
                    <input
                        type="number"
                        className="input-field"
                        onChange={(e) => onChangeDeliveryStartPrice(e.target.value)}
                        value={deliveryStartPrice}
                        min="0"
                    />
                    <FaCheck onClick={() => handleDeliveryStartPriceChange(deliveryStartPrice)} className="confirm-icon" />
                    </div>
                    </>
                )}
            </div>
            <div className="store-parameter">
                <p>
                Siret
                {dropdownSiret ? null : (
                    <strong>{siret}</strong>
                )}
                </p>
                <span className={`dropdown-arrow ${dropdownSiret ? 'open' : ''}`}  onClick={() => toggleDropdownSiret(siret)}>▼</span>
                {dropdownSiret && (
                    <>
                    <div className="time-picker-container">
                    <input
                        type="text"
                        className="input-field"
                        onChange={(e) => onChangeSiret(e.target.value)}
                        value={siret}
                        min="0"
                    />
                    <FaCheck onClick={() => handleSiretChange(siret)} className="confirm-icon" />
                    </div>
                    </>
                )}
            </div>
            </div>
        ) : (
            !error && <p>Loading store parameters...</p>
        )}
        </div>
    </div>
  );
};

export default StoreSettings;
