import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import './confirmDialog.css';  // Importation du fichier CSS

const confirmDialog = ({ open, onClose, onConfirm, msg }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirmer la suppression"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{msg}</DialogContentText>
      </DialogContent>
      <DialogActions className='btn-container'>
        <Button onClick={onConfirm} className="btn delete" autoFocus>
          Supprimer
        </Button>
        <Button onClick={onClose} className="btn cancel cancel-btn">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default confirmDialog;
