import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const useImageHandler = () => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');

  const authenticateImageServer = useCallback(async () => {
    try {
      const url = `${window.env.REACT_APP_SERVER_IMAGE}login`;
      const authResponse = await axios.post(url, {
        username: window.env.REACT_APP_USERNAME,
        password: window.env.REACT_APP_PASSWORD,
      });
      if (authResponse.data && authResponse.data.access_token) {
        setToken(authResponse.data.access_token);
      } else {
        console.error('Authentication response is missing the token:', authResponse.data);
        setError('Authentication failed');
      }
    } catch (error) {
      console.error('Error authenticating with image server:', error);
      setError('Error authenticating with image server');
    }
  }, []);

  useEffect(() => {
    authenticateImageServer();
  }, [authenticateImageServer]);

  const fetchImageUrl = useCallback(async (filename) => {
    try {
      if (!token) {
        console.error('Token is empty. Authentication may have failed.');
        setError('Token is empty. Authentication may have failed.');
        return null;
      }

      const imageUrl = filename;
      
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const imageUrlObject = URL.createObjectURL(response.data);
      return imageUrlObject;
    } catch (error) {
      console.error('Error fetching image:', error);
      setError('Error fetching image');
      return null;
    }
  }, [token]);

  const uploadImage = useCallback(async (imageFile) => {
    try {
      if (!['jpeg', 'png'].includes(imageFile.type.split('/')[1])) {
        throw new Error('Unsupported file type. Please upload JPEG or PNG files.');
      }
  
      const options = {
        maxSizeMB: 1, 
        maxWidthOrHeight: 800, 
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(imageFile, options);

      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('file', compressedImage);

      const url = `${window.env.REACT_APP_SERVER_IMAGE}images/${imageFile.name}`;
      const uploadResponse = await axios.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (uploadResponse.status === 201) {
        return uploadResponse.data.url;
      } else {
        console.error('Failed to upload image');
        setError('Failed to upload image');
        return null;
      }
    } catch (uploadError) {
      console.error('Error uploading image:', uploadError);
      setError('Error uploading image');
      return null;
    }
  }, [token]);

  return {
    fetchImageUrl,
    uploadImage,
    error,
  };
};

export default useImageHandler;
