import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaPlus } from 'react-icons/fa';

const fetchSupplement = async (menuId, setSupplements, setMenuName) => {
    try {
        const url = `${window.env.REACT_APP_BASE_URL}menu/${menuId}`;
        const res = await axios.get(url);
        setMenuName(res.data.name);
    } catch (err) {
        console.error('Error fetching data:', err);
    }

    try {
        const url = `${window.env.REACT_APP_BASE_URL}supplement_not_menu/?menu_id=${menuId}&store_id=${localStorage.getItem('storeId')}`;
        const res = await axios.get(url);
        setSupplements(res.data.supplements);
    } catch (err) {
        console.error('Error fetching data :', err);
    }
};

const SupplementMenu = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const menuId = queryParams.get('menuId');
    const [supplements, setSupplements] = useState([]);
    const [menuName, setMenuName] = useState('');

    useEffect(() => { 
        fetchSupplement(menuId, setSupplements, setMenuName);
    }, [menuId]);

    const handleAddSupplementToMenu = async (supplementId, tag_id) => {
        try {
            const url = `${window.env.REACT_APP_BASE_URL}menu_supplement/`;
            const res = await axios.post(url, {
                supplement_id: supplementId,
                menu_id: menuId,
                store_id: localStorage.getItem('storeId')
            },
            {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            if (res.status === 201) {
                fetchSupplement(menuId, setSupplements, setMenuName);
            } else {
                console.error('Failed to add supplement');
            }        
        } catch (err) {
            console.error('Error fetching data :', err);
        }

        try {
            const url = `${window.env.REACT_APP_BASE_URL}menu_constraint/?menu_id=${menuId}`;
            const res = await axios.get(url);

            const result = res.data.find(item => item.tag === tag_id);
            if (!result) {
                try {
                    const url = `${window.env.REACT_APP_BASE_URL}menu_constraint/`;
                    const response = await axios.post(url, {
                        menu_id: menuId,
                        tag_id: tag_id,
                        choices_required: 1
                    },
                    {
                        headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                        'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                    });   
                    if (!response.status === 201) {
                        console.error("Échec de l'operation");
                    } 
                } catch (err) {
                    console.error('Error fetching data :', err);
                }
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }

    const groupSuppByTag = (array) => {
        return array.reduce((acc, supplement) => {
            const { tag, tag_id } = supplement;
            if (!acc[tag]) {
                acc[tag] = { tag_id, supplements: [] };
            }
            acc[tag].supplements.push(supplement);
            return acc;
        }, {});
    };
    
    return (
        <div className='orders-container'>
            <div className="menu-banner">
                <h2 className="menu-name">{menuName}</h2> {/* Add this line */}
            </div>
            <div className="product-section supplements">
                <h2>
                    Ajouter des suppléments
                </h2> 
                <table className="product-table">
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prix</th>
                                <th>Stock</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(groupSuppByTag(supplements)).map(([tagName, { tag_id, supplements }]) => (
                                <React.Fragment key={tagName}>
                                    <tr>
                                        <td colSpan="4" style={{color: 'grey', fontWeight: 'bold', textAlign: 'left', paddingLeft:'50px'}}>{tagName}</td>
                                    </tr>
                                    {supplements.map((supplement) => (
                                        <tr key={supplement.id}>
                                            <td>{supplement.name}</td>
                                            <td>{supplement.price}</td>
                                            <td>
                                            <div>{supplement.in_stock ? 'En stock' : 'En rupture'}</div>
                                            </td>
                                            <td>
                                                <button 
                                                    className="fa-plus"
                                                    onClick={() => handleAddSupplementToMenu(supplement.id, tag_id)}
                                                >
                                                    <FaPlus />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
            </div>
        </div>
    )
}

export default SupplementMenu