import React, { useState, useEffect, useCallback } from 'react';
import './Supplements.css';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import ConfirmDialog from '../other/confirmDialog';

const Supplements = () => {
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [supplements, setSupplements] = useState([]);
  const [newSupplement, setNewSupplement] = useState({ name: '', price: '', in_stock: true, image_url: '' });
  const [editingSupplement, setEditingSupplement] = useState(null);
  const [editSupplementData, setEditSupplementData] = useState({ name: '', price: '', in_stock: false, image_url: '' });
  const [error, setError] = useState('');
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [supplementIdToDelete, setSupplementIdToDelete] = useState(null);

  // Fetch tags from the API
  const fetchTags = async (storeId) => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}tag/?store_id=${storeId}&is_supplement=true`;
      const response = await fetch(url);
      const data = await response.json();
      if (!data.error) {
        setTags(data.tags);
        if (data.tags.length > 0) {
          setSelectedTag(data.tags[0]);
        }
      }
    } catch (error) {
      console.error('Erreur lors du chargement des tags :', error);
    }
  };

  // Fetch supplements for a specific tag
  const fetchSupplements = useCallback(async (tagId) => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}supplement/?tag_id=${tagId}`;
      const response = await fetch(url);
      const data = await response.json();
      setSupplements(sortSupplements(data.supplements || []));
    } catch (error) {
      console.error('Erreur lors du chargement des ingrédients :', error);
    }
  }, []);

  // Fetch tags on component mount
  useEffect(() => {
    const storeId = localStorage.getItem('storeId');
    if (storeId) {
      fetchTags(storeId);
    }
  }, []);

  // Fetch ingredients when a tag is selected
  useEffect(() => {
    if (selectedTag) {
      fetchSupplements(selectedTag.id);
    }
  }, [selectedTag, fetchSupplements]);

  // Sort supplements by ID to maintain order
  const sortSupplements = (supplements) => {
    return supplements.sort((a, b) => a.id - b.id);
  };

  function normalizeString(str) {
    return str.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function tagExists(newTag, tags) {
    const normalizedNewTag = normalizeString(newTag);
    return tags.some(tag => normalizeString(tag.name) === normalizedNewTag);
  }

  // Add a new supplement
  const handleAddSupplement = async () => {
    if (!newSupplement.name || newSupplement.price === '') {
      setError('Données manquantes !');
      setTimeout(() => {
        setError('');
      }, 1000);
    } else if (tagExists(newSupplement.name, supplements)) {
      setError('Donnée existe déjà!');
      setTimeout(() => {
        setError('');
      }, 1000);
    } else {
      try {
        const formattedPrice = parseFloat(newSupplement.price).toFixed(2);
        const url = `${window.env.REACT_APP_BASE_URL}supplement/`;
        const response = await axios.post(
          url,
          { ...newSupplement, price: formattedPrice, tag_id: selectedTag.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );
        if (response.status === 201) {
          fetchSupplements(selectedTag.id);
          setNewSupplement({ name: '', price: '', in_stock: true, image_url: '' });
        } else {
          console.error('Échec de l\'ajout du supplément');
        }
      } catch (error) {
        console.error('Erreur :', error);
      }
    }
  };

  // Open the edit popup with the supplement data
  const handleOpenEditPopup = (supplement) => {
    setEditingSupplement(supplement);
    setEditSupplementData({ name: supplement.name, price: supplement.price, in_stock: supplement.in_stock, image_url: supplement.image_url });
    setOpenEditPopup(true);
  };

  // Close the edit popup
  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
    setEditingSupplement(null);
    setEditSupplementData({ name: '', price: '', in_stock: false, image_url: '' });
  };

  // Edit an supplement
  const handleEditSupplement = async () => {
    try {
      const formattedPrice = parseFloat(editSupplementData.price).toFixed(2);
      const url = `${window.env.REACT_APP_BASE_URL}supplement/`;
      const response = await axios.put(url,
        { id: editingSupplement.id, ...editSupplementData, price: formattedPrice, tag_id: selectedTag.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        fetchSupplements(selectedTag.id);
        handleCloseEditPopup();
      } else {
        console.error('Échec de la modification de supplement');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
  };

  // Open confirmation dialog for supplement deletion
  const handleOpenConfirmDialog = (id) => {
    setSupplementIdToDelete(id);
    setOpenConfirmDialog(true);
  };

  // Close the confirmation dialog
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  // Confirm deletion of an supplement
  const handleConfirmDelete = async () => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}supplement/`;
      const response = await axios.delete(url, {
        data: { id: supplementIdToDelete },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status === 204) {
        fetchSupplements(selectedTag.id);
      } else {
        console.error('Échec de la suppression de l\'ingrédient');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
    handleCloseConfirmDialog();
  };

  // Toggle in stock status of an supplement
  const toggleInStock = async (supplement) => {
    try {
      const url = `${window.env.REACT_APP_BASE_URL}supplement/`;
      const response = await axios.put(url,
        { id: supplement.id, ...supplement, in_stock: !supplement.in_stock, tag_id: selectedTag.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        fetchSupplements(selectedTag.id);
      } else {
        console.error('Échec de la mise à jour du statut en stock');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
  };

  // Format price to currency
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  };

  return (
    <div className="supplement-container">
      {tags.length === 0 ? (
        <p>Créez d'abord un ajout.</p>
      ) : (
        <>
          <div className='filter'>
            <ul className="filter-supplement">
              {tags.map((tag) => (
                <li
                  key={tag.id}
                  className={selectedTag && selectedTag.id === tag.id ? 'active' : ''}
                  onClick={() => setSelectedTag(tag)}
                >
                  {tag.name}
                </li>
              ))}
            </ul>
          </div>

          {selectedTag && (
            <div className="add-supplement">
              <input
                type="text"
                value={newSupplement.name}
                onChange={(e) => setNewSupplement({ ...newSupplement, name: e.target.value })}
                placeholder="Nom de l'ingrédient"
              />
              <div className="price-container">
                <input
                  type="number"
                  name="price"
                  value={newSupplement.price}
                  onChange={(e) => setNewSupplement({ ...newSupplement, price: e.target.value })}
                  placeholder="Prix"
                  min="0"
                />
                <span className="currency">€</span>
              </div>
              <label>
                En stock
                <input
                  type="checkbox"
                  checked={newSupplement.in_stock} // Case cochée par défaut
                  onChange={(e) => setNewSupplement({ ...newSupplement, in_stock: e.target.checked })}
                />
              </label>
              <button onClick={handleAddSupplement}>AJOUTER</button>
            </div>
          )}

          {error && (
            <div className="errorResult">
              Erreur : {error}
            </div>
          )}

          <table>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prix</th>
                <th>En Stock</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {supplements.map((supplement) => (
                <tr key={supplement.id}>
                  <td>{supplement.name}</td>
                  <td>{formatPrice(supplement.price)}</td>
                  <td>
                    <label className="switch">
                      <input 
                        type="checkbox" 
                        checked={supplement.in_stock} 
                        onChange={() => toggleInStock(supplement)} 
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>
                  <td>
                    <FaEdit
                      className="fa-edit"
                      onClick={() => handleOpenEditPopup(supplement)}
                    />
                    <FaTrash
                      className="fa-trash"
                      onClick={() => handleOpenConfirmDialog(supplement.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {editingSupplement && (
            <div className={`popup-supplement ${openEditPopup ? 'open' : ''}`}>
              <div className="popup-content">
                <h3>Modifier l'ingrédient</h3>
                <input
                  type="text"
                  value={editSupplementData.name}
                  onChange={(e) => setEditSupplementData({ ...editSupplementData, name: e.target.value })}
                  placeholder="Nom de supplement"
                />
                <input
                  type="number"
                  value={editSupplementData.price}
                  onChange={(e) => setEditSupplementData({ ...editSupplementData, price: e.target.value })}
                  placeholder="Prix"
                  min="0"
                />
                <label>
                  En Stock
                  <input
                    type="checkbox"
                    checked={editSupplementData.in_stock}
                    onChange={(e) => setEditSupplementData({ ...editSupplementData, in_stock: e.target.checked })}
                  />
                </label>
                <div className="button-container">
                  <button className="save-button" onClick={handleEditSupplement}>Enregistrer</button>
                  <button className="cancel-button" onClick={handleCloseEditPopup}>Annuler</button>
                </div>
              </div>
            </div>
          )}

          <ConfirmDialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            onConfirm={handleConfirmDelete}
            msg={'Êtes-vous sûr de vouloir supprimer cet ingrédient ? Cette action est irréversible.'}
          />
        </>
      )}
    </div>
  );
};

export default Supplements;
