import React from 'react';
import './Sidebar.css';
import { FaListAlt, FaList, FaCog } from 'react-icons/fa';
import { FaPlus, FaAppleAlt, FaCapsules } from 'react-icons/fa'; // Font Awesome icons
import { BiSolidFoodMenu } from "react-icons/bi";
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className='sidebar'>
          <div className='sidebar-options'>
            <NavLink
              to='/restaurants/orders'
              className={({ isActive }) => 'sidebar-option' + (isActive ? ' active' : '')}
            >
              <FaListAlt className='icon' />
              <p>Commandes</p> 
            </NavLink>
            <NavLink
              to='/restaurants/categories'
              className={({ isActive }) => 'sidebar-option' + (isActive ? ' active' : '')}
            >
              <FaList className='icon' />
              <p>Catégories</p> 
            </NavLink>
            <NavLink
              to='/restaurants/ajouts'
              className={({ isActive }) => 'sidebar-option' + (isActive ? ' active' : '')}
            >
              <FaPlus className='icon' />
              <p>Ajouts</p> 
            </NavLink>
            <NavLink
              to='/restaurants/supplements'
              className={({ isActive }) => 'sidebar-option' + (isActive ? ' active' : '')}
            >
              <FaCapsules className='icon' />
              <p>Suppléments</p> 
            </NavLink>
            <NavLink
              to='/restaurants/ingredients'
              className={({ isActive }) => 'sidebar-option' + (isActive ? ' active' : '')}
            >
              <FaAppleAlt className='icon' />
              <p>Ingrédients</p> 
            </NavLink>
            <NavLink
              to='/restaurants/menu'
              className={({ isActive }) => 'sidebar-option' + (isActive ? ' active' : '')}
            >
              <BiSolidFoodMenu className='icon' />
              <p>Menu</p> 
            </NavLink>
            <NavLink
              to='/restaurants/settings'
              className={({ isActive }) => 'sidebar-option' + (isActive ? ' active' : '')}
            >
              <FaCog className='icon' />
              <p>Paramètres</p>
            </NavLink>
          </div>
    </div>
  );
};

export default Sidebar;
