import React, { useState, useEffect } from 'react';
import './ProductMenu.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';

const fetchProducts = async (menuId, setSupplements, setIngredients, setMenuName, setConstraints) => {
    try {
        const url = `${window.env.REACT_APP_BASE_URL}menu/${menuId}`;
        const res = await axios.get(url);
        setMenuName(res.data.name);
        setSupplements(res.data.supplements);
        setIngredients(res.data.ingredients);
    } catch (err) {
        console.error('Error fetching data:', err);
    }

    try {
        const url = `${window.env.REACT_APP_BASE_URL}menu_constraint/?menu_id=${menuId}`;
        const res = await axios.get(url);
        setConstraints(res.data);
    } catch (err) {
        console.error('Error fetching data:', err);
    }
};

const ProductMenu = () => {
    const [ingredients, setIngredients] = useState([]);
    const [supplements, setSupplements] = useState([]);
    const [menuName, setMenuName] = useState('');
    const [constraints, setConstraints] = useState([]);
    const [editingConstraint, setEditingConstraint] = useState(null);
    const [editMin, setEditMin] = useState(null);
    const [editMax, setEditMax] = useState(null);
    const location = useLocation();
    const [error, setError] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const menuId = queryParams.get('menuId');
    
    useEffect(() => { 
        fetchProducts(menuId, setSupplements, setIngredients, setMenuName, setConstraints);
    }, [menuId]);

    const handleDeleteIngredient = async (ingredientId) => {
        try {
            const url = `${window.env.REACT_APP_BASE_URL}menu_ingredient/`;
            const res = await axios.delete(url, {
                data: { ingredient_id: ingredientId, menu_id: menuId, store_id: localStorage.getItem('storeId')},
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            
            if (res.status === 204) {
                fetchProducts(menuId, setSupplements, setIngredients, setMenuName, setConstraints);
            } else {
                console.error('Failed to delete ingredient');
            }        
        } catch (err) {
            console.error('Error fetching data :', err);
        }
    }

    const handleDeleteSupplement = async (supplementId) => {
        try {
            const url = `${window.env.REACT_APP_BASE_URL}menu_supplement/`;
            const res = await axios.delete(url, {
                data: { supplement_id: supplementId, menu_id: menuId, store_id: localStorage.getItem('storeId')},
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            
            if (res.status === 204) {
                fetchProducts(menuId, setSupplements, setIngredients, setMenuName, setConstraints);
            } else {
                console.error('Failed to delete supplement');
            }        
        } catch (err) {
            console.error('Error fetching data :', err);
        }
    }

    const inStock = async (item) => {
        try {
            const url = `${window.env.REACT_APP_BASE_URL}supplement/`;
            await axios.put(
                url, 
                {
                    id: item.id,
                    in_stock: !item.in_stock,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );
    
            setSupplements((prevSupplements) =>
                prevSupplements.map((supplement) =>
                    supplement.id === item.id
                        ? { ...supplement, in_stock: !supplement.in_stock }
                        : supplement
                )
            );
        } catch (err) {
          console.error(err.response?.data || err.message);
        }
    };

    const inStock2 = async (item) => {
        try {
            const url = `${window.env.REACT_APP_BASE_URL}ingredient/`;
            await axios.put(
                url, 
                {
                    id: item.id,
                    in_stock: !item.in_stock,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );
    
            setIngredients((prevIngredients) =>
                prevIngredients.map((ingredient) =>
                    ingredient.id === item.id
                        ? { ...ingredient, in_stock: !ingredient.in_stock }
                        : ingredient
                )
            );
        } catch (err) {
          console.error(err.response?.data || err.message);
        }
    };

    const handleMenuConstraint = async (tag_id) => {
        if(editMax>=editMin){
            try {
                const url = `${window.env.REACT_APP_BASE_URL}menu_constraint/`;

                let params;
                if(editMax===editMin){
                    params = {
                        menu_id: menuId,
                        tag_id: tag_id,
                        choices_required: editMin
                    }
                }else{
                    params = {
                        menu_id: menuId,
                        tag_id: tag_id,
                        min_required: editMin,
                        max_required: editMax
                    }
                }

                const response = await axios.post(url, params,
                {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                });   
                if (response.status === 201) {
                    setConstraints(prevConstraints => {
                        const constraintExists = prevConstraints.some(constraint => constraint.tag === editingConstraint.tag);
                        
                        if (constraintExists) {
                            return prevConstraints.map(constraint =>
                                constraint.tag === editingConstraint.tag
                                    ? { ...constraint, max_required: editMax, min_required: editMin }
                                    : constraint
                            );
                        } else {
                            return [
                                ...prevConstraints,
                                {
                                    menu: menuId,
                                    tag: editingConstraint.tag,
                                    min_required: editMin,
                                    max_required: editMax
                                }
                            ];
                        }
                    });
                                      
                    setEditingConstraint(null);
                    setEditMax(1);
                    setEditMin(1);
                } else {
                    console.error("Échec de l'operation");
                }  
            } catch (err) {
                console.error('Error fetching data :', err);
            }
        }else{
            setError('Le Min doit être inférieur ou égal au Max!');
            setTimeout(() => setError(''), 1000);
        }  
    };

    const groupIngrByTag = (array) => {
        return array.reduce((acc, ingredient) => {
            const { tag, tag_id } = ingredient;
            if (!acc[tag]) {
                acc[tag] = { tag_id, ingredients: [] };
            }
            acc[tag].ingredients.push(ingredient);
            return acc;
        }, {});
    };
    
    const groupSuppByTag = (array) => {
        return array.reduce((acc, supplement) => {
            const { tag, tag_id } = supplement;
            if (!acc[tag]) {
                acc[tag] = { tag_id, supplements: [] };
            }
            acc[tag].supplements.push(supplement);
            return acc;
        }, {});
    };

    const editExact = (val) => {
        setEditMin(val);
        setEditMax(val);
    };

    return (
        <div className="orders-container">
            {error && (
                <div className="errorResult"> {error} </div>
            )}
            <div className="menu-banner">
                <h2 className="menu-name">{menuName}</h2>
            </div>
            <div className="product-section ingredients">
                <h2>
                Ingrédients du menu (Obligatoires) &nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink 
                    className="fa-plus"
                    to={`./ingredients?menuId=${menuId}`}
                >
                    <FaPlus />
                </NavLink>
                </h2> 
                <div className="product-table-container">
                    <table className="product-table">
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prix</th>
                                <th>Stock</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Object.entries(groupIngrByTag(ingredients)).map(([tagName, { tag_id, ingredients }]) => (
                            <React.Fragment key={tagName}>
                                <td colSpan="4">
                                    <tr className="table-row">
                                        <td style={{color: 'grey', fontWeight: 'bold', textAlign: 'center'}}>
                                            {tagName.toUpperCase()} &nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        {editingConstraint && editingConstraint.tag === tag_id ? (
                                            <div className="constraint-form-container">
                                                <label className="constraint-label">
                                                    Exact :
                                                    <input
                                                        className="constraint-input"
                                                        min="0"
                                                        type="number"
                                                        value={editMin === editMax ? editMax : null}
                                                        onChange={(e) => editExact(e.target.value)}
                                                    />
                                                </label>

                                                <label className="constraint-label">
                                                    Min :
                                                    <input
                                                        className="constraint-input"
                                                        min="0"
                                                        max={editMax}
                                                        type="number"
                                                        value={editMin}
                                                        onChange={(e) => setEditMin(e.target.value)}
                                                    />
                                                </label>

                                                <label className="constraint-label">
                                                    Max :
                                                    <input
                                                        className="constraint-input"
                                                        min={editMin}
                                                        type="number"
                                                        value={editMax}
                                                        onChange={(e) => setEditMax(e.target.value)}
                                                    />
                                                </label>

                                                <button className="save-button" onClick={() => handleMenuConstraint(tag_id)}>
                                                    Enregistrer
                                                </button>
                                                <button className="save-button" onClick={() => setEditingConstraint(null)}>
                                                    Anuller  
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                <td className="table-cell">
                                                    <span className="amount-label">Quantité</span>
                                                </td>
                                                <td className="table-cell amount-value">
                                                    {(() => {
                                                        const constraint = constraints.find(c => c.tag === tag_id);
                                                        if (constraint) {
                                                            const { min_required, max_required } = constraint;
                                                            return min_required === max_required
                                                            ? `Exact : ${min_required}`
                                                            : `Min : ${min_required} | Max : ${max_required}`;
                                                        } else {
                                                            return `Exact : 1`;
                                                        }
                                                    })()}
                                                </td>
                                                <td className="table-cell">
                                                    <FaEdit 
                                                        className="fa-edit edit-icon"
                                                        onClick={() => {
                                                            const constraint = constraints.find(c => c.tag === tag_id);
                                                            if (constraint) {
                                                                setEditingConstraint(constraint);
                                                                setEditMin(constraint.min_required);
                                                                setEditMax(constraint.max_required);
                                                            }else{
                                                                setEditingConstraint({"tag":tag_id});
                                                                setEditMin(1);
                                                                setEditMax(1);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                </td>
                                {ingredients.map((ingredient) => (
                                    <tr key={ingredient.id}>
                                        <td>{ingredient.name}</td>
                                        <td>{ingredient.price}</td>
                                        <td>
                                            <label className="switch">
                                                <input type="checkbox" checked={ingredient.in_stock} onChange={() => inStock2(ingredient)} />
                                                <span className="slider round"></span>
                                            </label>
                                        </td>
                                        <td>
                                            <FaTrash
                                                className="fa-trash"
                                                onClick={() => handleDeleteIngredient(ingredient.id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="product-section supplements">
                <h2>
                Suppléments du menu (Par Choix) &nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink 
                    className="fa-plus"
                    to={`./supplements?menuId=${menuId}`}  // Pass the menuId as a query parameter
                >
                    <FaPlus />
                </NavLink>
                </h2>
                <div className="product-table-container">
                    <table className="product-table">
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prix</th>
                                <th>Stock</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(groupSuppByTag(supplements)).map(([tagName, { tag_id, supplements }]) => (
                                <React.Fragment key={tagName}>
                                    <td colSpan="4">
                                        <tr className="table-row">
                                            <td style={{color: 'grey', fontWeight: 'bold', textAlign: 'center'}}>
                                                {tagName.toUpperCase()} &nbsp;&nbsp;&nbsp;&nbsp;
                                            </td>
                                            {editingConstraint && editingConstraint.tag === tag_id ? (
                                                <div className="constraint-form-container">
                                                    <label className="constraint-label">
                                                        Exact :
                                                        <input
                                                            className="constraint-input"
                                                            min="0"
                                                            type="number"
                                                            value={editMin === editMax ? editMax : null}
                                                            onChange={(e) => editExact(e.target.value)}
                                                        />
                                                    </label>

                                                    <label className="constraint-label">
                                                        Min :
                                                        <input
                                                            className="constraint-input"
                                                            min="0"
                                                            max={editMax}
                                                            type="number"
                                                            value={editMin}
                                                            onChange={(e) => setEditMin(e.target.value)}
                                                        />
                                                    </label>

                                                    <label className="constraint-label">
                                                        Max :
                                                        <input
                                                            className="constraint-input"
                                                            min={editMin}
                                                            type="number"
                                                            value={editMax}
                                                            onChange={(e) => setEditMax(e.target.value)}
                                                        />
                                                    </label>

                                                    <button className="save-button" onClick={() => handleMenuConstraint(tag_id)}>
                                                        Enregistrer
                                                    </button>
                                                    <button className="save-button" onClick={() => setEditingConstraint(null)}>
                                                        Anuller  
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    <td className="table-cell">
                                                        <span className="amount-label">Quantité</span>
                                                    </td>
                                                    <td className="table-cell amount-value">
                                                        {(() => {
                                                        const constraint = constraints.find(c => c.tag === tag_id);
                                                        if (constraint) {
                                                            const { min_required, max_required } = constraint;
                                                            return min_required === max_required
                                                            ? `Exact : ${min_required}`
                                                            : `Min : ${min_required} | Max : ${max_required}`;
                                                        } else {
                                                            return `Exact : 1`;
                                                        }
                                                        })()}
                                                    </td>
                                                    <td className="table-cell">
                                                        <FaEdit 
                                                            className="fa-edit edit-icon"
                                                            onClick={() => {
                                                                const constraint = constraints.find(c => c.tag === tag_id);
                                                                if (constraint) {
                                                                    setEditingConstraint(constraint);
                                                                    setEditMin(constraint.min_required);
                                                                    setEditMax(constraint.max_required);
                                                                }else{
                                                                    setEditingConstraint({"tag":tag_id});
                                                                    setEditMin(1);
                                                                    setEditMax(1);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </>

                                            )}
                                        </tr>
                                    </td>
                                    {supplements.map((supplement) => (
                                        <tr key={supplement.id}>
                                            <td>{supplement.name}</td>
                                            <td>{supplement.price}</td>
                                            <td>
                                                <label className="switch">
                                                    <input type="checkbox" checked={supplement.in_stock} onChange={() => inStock(supplement)} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <FaTrash
                                                    className="fa-trash"
                                                    onClick={() => handleDeleteSupplement(supplement.id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ProductMenu;
