import React, { useState, useEffect, useCallback } from 'react';
import './Menu.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { FaEdit, FaTrash, FaEye, FaUpload } from 'react-icons/fa';
import ConfirmDialog from '../other/confirmDialog';
import ImageHandler from '../ImageHandler/ImageHandler';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const storeId = localStorage.getItem('storeId');
const base_url = window.env.REACT_APP_BASE_URL;

const Menu = () => {
  const { uploadImage, fetchImageUrl } = ImageHandler();
  const [menues, setMenues] = useState([]);
  const [filter, setFilter] = useState(1);
  const [categories, setCategories] = useState([]);
  const [newMenu, setNewMenu] = useState({
    name: '',
    description: '',
    price: 0,
    in_stock: true,
    ingredients: [],
    supplements: [],
    category: 1,
    image_url: '',
    image_file: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [menuIdToDelete, setMenuIdToDelete] = useState(null);
  const [error, setError] = useState('');
  const [taxes, setTaxes] = useState([]); //TVA

  const fetchTaxes = async () => {
    try {
      const response = await fetch(`${base_url}taxes/${storeId}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const data = await response.json();
      setTaxes(data);
      if (response.ok) {
        console.log('Taxes:', data.taxes);
      } else {
        console.error('Error fetching taxes:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }; 

  const handleEditClick = (menu) => {
    setEditingMenu(menu);
  };

  const handleDeleteClick = (menuId) => {
    setOpenDeleteDialog(true);
    setMenuIdToDelete(menuId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    deleteMenu(menuIdToDelete);
    setOpenDeleteDialog(false);
  };
  
  const handleSave = async (item) => {
    let imageUrl = item.image_url; // Garder l'URL actuelle de l'image par défaut
    if (item.image_file) {
      imageUrl = await uploadImage(item.image_file); // Téléchargez la nouvelle image
      if (imageUrl) {
        item.image_url = imageUrl; // Mettez à jour l'URL de l'image
        setCurrentItem({ ...currentItem, image_url: imageUrl }); // Mettez à jour immédiatement currentItem avec la nouvelle URL
      } else {
        console.error('Erreur lors du téléchargement de l\'image');
        return; // Ne pas continuer si l'image n'a pas pu être téléchargée
      }
    }
 
    // Mise à jour du menu avec les nouvelles données
    editMenu(item);
    handleCloseModal();
  };
 
  const editMenu = async (item) => {
    try {
      let imageUrl = item.image_url; // Utilisez l'URL actuelle de l'image par défaut
      if (item.image_file) { // Si un nouveau fichier image est sélectionné
        imageUrl = await uploadImage(item.image_file); // Téléchargez la nouvelle image
        if (!imageUrl) {
          console.error("Échec du téléchargement de l'image");
          return;
        }
      }

      // Fetch supplements and ingredients
      const url = `${base_url}supplement/?menu_id=${item.id}`;
      const res = await axios.get(url);
      const supplements = res.data.supplements;
      const idsSupplement = supplements.map((supplement) => supplement.id);
 
      const url2 = `${base_url}ingredient/?menu_id=${item.id}`;
      const res2 = await axios.get(url2);
      const ingredients = res2.data.ingredients;
      const idsIngredient = ingredients.map((ingredient) => ingredient.id);
 
      // Update the menu item with the new or existing image URL
      const url3 = `${base_url}menu/update/${item.id}/`;
      await axios.put(
        url3,
        {
          name: currentItem.name,
          description: currentItem.description,
          price: currentItem.price,
          in_stock: item.in_stock,
          ingredients: idsIngredient,
          supplements: idsSupplement,
          category: item.category,
          image_url: imageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      const updatedImageUrl = await fetchImageUrl(imageUrl);
 
      setMenues((prevMenus) =>
        prevMenus.map((menu) =>
          menu.id === item.id
            ? {
                ...menu,
                name: currentItem.name,
                description: currentItem.description,
                price: currentItem.price,
                in_stock: item.in_stock,
                ingredients: idsIngredient,
                supplements: idsSupplement,
                category: item.category,
                image_url: updatedImageUrl,
              }
            : menu
        )
      );
    } catch (err) {
      console.error(err.response?.data || err.message);
    }
    handleCloseModal();
  };

  const setEditingMenu = (item) => {
    setCurrentItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentItem(null);
  };

  const fetchMenus = useCallback(async () => {
    if (!storeId) {
      console.error('Store ID is missing in localStorage');
      return;
    }

    let url;
    if (filter !== null) {
      url = `${base_url}menus/by_category/${filter}/`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (Array.isArray(data)) {
        const menusWithImages = await Promise.all(
          data.map(async (menu) => {
            if (menu.image_url) {
              const imageUrl = await fetchImageUrl(menu.image_url);
              return { ...menu, image_url: imageUrl };
            }
            return menu;
          })
        );
        setMenues(menusWithImages);
      } else {
        setMenues([]);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }, [filter, fetchImageUrl]);

  const fetchCategories = async (storeId) => {
    try {
      const url = `${base_url}category/?store_id=${storeId}`;
      const response = await fetch(url);
      const data = await response.json();
      if (!data.error) {
        setCategories(data.categories);
        if (data.categories.length > 0) {
          setFilter(data.categories[0].id);
        }
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchTaxes();
    if (storeId) {
      fetchCategories(storeId);
      fetchMenus();
    }
  }, []);

  useEffect(() => {
    if (filter !== null) {
      fetchMenus();
    }
  }, [fetchMenus, filter]);

  const handleFilterClick = (filter) => {
    setFilter(filter);
  };

  const handleAddMenu = async () => {
    if (newMenu.image_file) {
      const imageUrl = await uploadImage(newMenu.image_file);
      if (imageUrl) {
        newMenu.image_url = imageUrl;
      } else {
        setError('Échec du téléchargement de l\'image. Assurez-vous de télécharger un fichier JPEG ou PNG.');
        setTimeout(() => {
          setError('');
        }, 1000);
        return;
      }
    }

    try {
      const url = `${base_url}menu/add/`;
      const response = await axios.post(
        url,
        {
          name: newMenu.name,
          description: newMenu.description,
          price: newMenu.price,
          in_stock: newMenu.in_stock,
          ingredients: newMenu.ingredients,
          supplements: newMenu.supplements,
          category: filter,
          image_url: newMenu.image_url ? newMenu.image_url : "https://example.com/placeholder-image.jpg",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.status === 201) {
        setNewMenu({
          name: '',
          description: '',
          price: 0,
          in_stock: true,
          ingredients: [],
          supplements: [],
          category: 1,
          image_url: '',
          image_file: null,
        });
        fetchMenus();
      } else {
        console.error('Échec de l\'ajout du menu');
      }
    } catch (error) {
      console.error('Erreur:', error);
      setError('Données manquantes !');
      setTimeout(() => {
        setError('');
      }, 1000);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewMenu({
      ...newMenu,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const deleteMenu = async (menuId) => {
    try {
      const url = `${base_url}menu/remove/${menuId}/`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status === 204) {
        fetchMenus();
      } else {
        console.error('Failed to delete menu');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const inStock = async (item) => {
    try {
      const inStock = !item.in_stock;
      const menuId = item.id;

      const url = `${base_url}supplement/?menu_id=${menuId}`;
      const res = await axios.get(url);
      const supplements = res.data.supplements;
      const idsSupplement = supplements.map((supplement) => supplement.id);

      const url2 = `${base_url}ingredient/?menu_id=${menuId}`;
      const res2 = await axios.get(url2);
      const ingredients = res2.data.ingredients;
      const idsIngredient = ingredients.map((ingredient) => ingredient.id);

      const url3 = `${base_url}menu/update/${menuId}/`;
      await axios.put(
        url3,
        {
          name: item.name,
          description: item.description,
          price: item.price,
          in_stock: inStock,
          ingredients: idsIngredient,
          supplements: idsSupplement,
          category: item.category,
          tax: item.tax,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      setMenues((prevMenus) =>
        prevMenus.map((menu) =>
          menu.id === item.id
            ? {
              ...menu,
              name: item.name,
              description: item.description,
              price: item.price,
              in_stock: inStock,
              ingredients: idsIngredient,
              supplements: idsSupplement,
              category: item.category,
              tax: item.tax,
            }
            : menu
        )
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const taxMenu = async (item, newTax) => {
    try {
      const menuId = item.id;

      const url = `${base_url}supplement/?menu_id=${menuId}`;
      const res = await axios.get(url);
      const supplements = res.data.supplements;
      const idsSupplement = supplements.map((supplement) => supplement.id);

      const url2 = `${base_url}ingredient/?menu_id=${menuId}`;
      const res2 = await axios.get(url2);
      const ingredients = res2.data.ingredients;
      const idsIngredient = ingredients.map((ingredient) => ingredient.id);

      const url3 = `${base_url}menu/update/${menuId}/`;
      await axios.put(
        url3,
        {
          name: item.name,
          description: item.description,
          price: item.price,
          in_stock: item.in_stock,
          ingredients: idsIngredient,
          supplements: idsSupplement,
          category: item.category,
          tax: newTax,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      setMenues((prevMenus) =>
        prevMenus.map((menu) =>
          menu.id === item.id
            ? {
              ...menu,
              name: item.name,
              description: item.description,
              price: item.price,
              in_stock: item.in_stock,
              ingredients: idsIngredient,
              supplements: idsSupplement,
              category: item.category,
              tax: newTax,
            }
            : menu
        )
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
  
    const items = Array.from(menues);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setMenues(items);
  
    const orderData = items.map((menu, index) => ({ id: menu.id, order: index }));
    try {
      const url = `${base_url}update_order_menus/`;
      await axios.put(url, orderData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
    } catch (error) {
      console.error('Failed to update menu order:', error);
    }
  };
  

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Process the file as needed
    setNewMenu({ ...newMenu, image_file: file });
  };

const handleFileChangeModal = (event) => {
    const file = event.target.files[0];
    setCurrentItem({ ...currentItem, image_file: file });
  };

  return (
    <div className="orders-container">
      {categories.length === 0 ? (
        <p>Create a category first.</p>
      ) : (
        <>
          <div className="filter">
            <ul className="filters-menu">
              {categories.map(category => (
                <li key={category.id} onClick={() => handleFilterClick(category.id)} className={filter === category.id ? 'active' : ''}>{category.name}</li>
              ))}
            </ul>
          </div>
          <div className="add-menu">
            <input
              type="text"
              name="name"
              value={newMenu.name}
              onChange={handleChange}
              placeholder="Nom du Menu"
            />
            <input
              type="text"
              name="description"
              value={newMenu.description}
              onChange={handleChange}
              placeholder="Description du menu"
            />
            <div className="price-container">
              <input
                type="number"
                name="price"
                value={newMenu.price}
                onChange={handleChange}
                placeholder="Prix"
                min="0"
              />
              <span className="currency">€</span>
            </div>
            <label className="checkbox-container">
              Stock
              <input
                type="checkbox"
                name="in_stock"
                checked={newMenu.in_stock}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
            <div className="file-input-container">
              <input type="file" id="file-upload" onChange={handleFileChange} />
              <label htmlFor="file-upload" className="file-label">
                <FaUpload className="fa-upload" />
              </label>
              <span className="tooltip">Télécharger une photo</span>
            </div>
            <button onClick={handleAddMenu}>AJOUTER</button>
          </div>
          {error && (
            <div className="errorResult">
              Erreur : {error}
            </div>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="menus">
              {(provided) => (
                <table {...provided.droppableProps} ref={provided.innerRef}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Description</th>
                      <th>Prix</th>
                      <th>Stock</th>
                      <th>Image</th>
                      <th>Taxe</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menues.map((menu, index) => (
                      <Draggable key={menu.id.toString()} draggableId={menu.id.toString()} index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>{menu.name}</td>
                            <td>{menu.description}</td>
                            <td>{menu.price}€</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" checked={menu.in_stock} onChange={() => inStock(menu)} />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              {menu.image_url ? (
                                <img src={menu.image_url} alt={menu.name} className="table-image" />
                              ) : (
                                <p>Pas d'image</p>
                              )}
                            </td>
                            <td className="tax-dropdown-cell">
                              <select
                                className="tax-dropdown"
                                onChange={(e) => taxMenu(menu, e.target.value)}
                                value={menu.tax ? menu.tax : ''}
                              >
                                {menu.tax === null ? (
                                  <option value="">Sélectionner</option>
                                ) : (
                                  (() => {
                                    const selectedTax = taxes.find(item => item.id === menu.tax);
                                    return (
                                      selectedTax && (
                                        <option value={selectedTax.id}>
                                          {selectedTax.name} ({selectedTax.percentage}%)
                                        </option>
                                      )
                                    );
                                  })()
                                )}

                                {taxes.map((tax, index) => (
                                  <option key={index} value={tax.id}>
                                    {tax.name} ({tax.percentage}%)
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <NavLink to={`./ProductMenu?menuId=${menu.id}`}>
                                  <FaEye className="fa-trash"/> 
                              </NavLink>
                              <FaEdit
                                className="fa-edit"
                                onClick={() => handleEditClick(menu)}
                              />
                              <FaTrash
                                className="fa-trash"
                                onClick={() => handleDeleteClick(menu.id)}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    <ConfirmDialog
                      open={openDeleteDialog}
                      onClose={handleCloseDeleteDialog}
                      onConfirm={handleConfirmDelete}
                      msg={'Êtes-vous sûr de vouloir supprimer ce menu ? Cette action est irréversible.'}
                    />
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2>Modifier Menu</h2>
                <label>
                  <input
                    type="text"
                    value={currentItem.name}
                    onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                    placeholder="Nom du Menu"
                  />
                </label>
                <label>
                  <textarea
                    value={currentItem.description}
                    onChange={(e) => setCurrentItem({ ...currentItem, description: e.target.value })}
                    placeholder="Description du menu"
                  />
                </label>
                <label>
                  <input
                    type="number"
                    value={currentItem.price}
                    onChange={(e) => setCurrentItem({ ...currentItem, price: parseFloat(e.target.value) })}
                    placeholder="Prix"
                    min="0"
                  />
                </label>
                <div className="image-container">
                  {currentItem.image_url && (
                    <img src={currentItem.image_url} alt={currentItem.name} className="modal-image" />
                  )}
                  <div>
                    <input
                      type="file"
                      onChange={handleFileChangeModal}
                    />
                  </div>
                </div>
                <div className="button-container">
                  <button className="save-button" onClick={() => handleSave(currentItem)}>Enregistrer</button>
                  <button className="cancel-button" onClick={handleCloseModal}>Annuler</button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Menu;