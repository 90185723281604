import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaPlus } from 'react-icons/fa';

const storeId = localStorage.getItem('storeId');

const fetchIngredient = async (menuId, setIngredients, setMenuName) => {
    try {
        const url = `${window.env.REACT_APP_BASE_URL}menu/${menuId}`;
        const res = await axios.get(url);
        setMenuName(res.data.name);
    } catch (err) {
        console.error('Error fetching data:', err);
    }

    try {
        const url = `${window.env.REACT_APP_BASE_URL}ingredient_not_menu/?menu_id=${menuId}&store_id=${storeId}`;
        const res = await axios.get(url);
        setIngredients(res.data.ingredients);
    } catch (err) {
        console.error('Error fetching data :', err)
    }
};

const IngredientMenu = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const menuId = queryParams.get('menuId');
    const [ingredients, setIngredients] = useState([]);
    const [menuName, setMenuName] = useState('');

    useEffect(() => { 
        fetchIngredient(menuId, setIngredients, setMenuName);
    }, [menuId]);

    const handleAddIngredientToMenu = async (ingredientId, tag_id) => {
        try {
            const url = `${window.env.REACT_APP_BASE_URL}menu_ingredient/`;
            const res = await axios.post(url, {
                ingredient_id: ingredientId,
                menu_id: menuId,
                store_id: storeId
            },
            {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            if (res.status === 201) {
                fetchIngredient(menuId, setIngredients, setMenuName);
            } else {
                console.error('Failed to add ingredient');
            }        
        } catch (err) {
            console.error('Error fetching data :', err);
        }

        try {
            const url = `${window.env.REACT_APP_BASE_URL}menu_constraint/?menu_id=${menuId}`;
            const res = await axios.get(url);

            const result = res.data.find(item => item.tag === tag_id);
            if (!result) {
                try {
                    const url = `${window.env.REACT_APP_BASE_URL}menu_constraint/`;
                    const response = await axios.post(url, {
                        menu_id: menuId,
                        tag_id: tag_id,
                        choices_required: 1
                    },
                    {
                        headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                        'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                    });   
                    if (!response.status === 201) {
                        console.error("Échec de l'operation");
                    } 
                } catch (err) {
                    console.error('Error fetching data :', err);
                }
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }

    const groupIngrByTag = (array) => {
        return array.reduce((acc, ingredient) => {
            const { tag, tag_id } = ingredient;
            if (!acc[tag]) {
                acc[tag] = { tag_id, ingredients: [] };
            }
            acc[tag].ingredients.push(ingredient);
            return acc;
        }, {});
    };
    
    return (
        <div className='orders-container'>
            <div className="menu-banner">
                <h2 className="menu-name">{menuName}</h2> {/* Add this line */}
            </div>

            <div className="product-section ingredients">
                <h2>
                    Ajouter des ingrédients
                </h2> 
                    <table className="product-table">
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prix</th>
                                <th>Stock</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(groupIngrByTag(ingredients)).map(([tagName, { tag_id, ingredients }]) => (
                                <React.Fragment key={tagName}>
                                    <tr>
                                        <td colSpan="4" style={{color: 'grey', fontWeight: 'bold', textAlign: 'left', paddingLeft:'50px'}}>{tagName}</td>
                                    </tr>
                                    {ingredients.map((ingredient) => (
                                        <tr key={ingredient.id}>
                                            <td>{ingredient.name}</td>
                                            <td>{ingredient.price}</td>
                                            <td>
                                            <div>{ingredient.in_stock ? 'En stock' : 'En rupture'}</div>
                                            </td>
                                            <td>
                                                <button 
                                                    className="fa-plus"
                                                    onClick={() => handleAddIngredientToMenu(ingredient.id, tag_id)}
                                                >
                                                    <FaPlus />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
            </div>
        </div>
    )
}

export default IngredientMenu