import React from 'react';
import './Settings.css';
import { NavLink } from 'react-router-dom';
import { FaStore, FaPrint, FaPercentage } from 'react-icons/fa';

const Settings = () => {
  return (
    <div className="orders-container">
      <div className="settings-container">
        <div className="settings-header">
          <h2>Paramètres</h2>
        </div>
        <div className="store-parameters">
          <NavLink to="./store" className="store-parameter">
            <FaStore className="icon" />
            <p>Paramètres de store</p>
          </NavLink>
          <NavLink to="./printer" className="store-parameter">
            <FaPrint className="icon" />
            <p>Paramètres d'impression</p>
          </NavLink>
          <NavLink to="./tva" className="store-parameter">
            <FaPercentage className="icon" />
            <p>Paramètres de Fiscalité (TVA)</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Settings;
